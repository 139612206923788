import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ErrorFallback from 'components/ErrorFallback/ErrorFallback';
import useLanguageProvider from 'components/LanguageProvider/hooks/useLanguageProvider';
import CampaignUpdateTimeline from 'pages/campaign/components/CampaignUpdates/components/CampaignUpdateTimeline/CampaignUpdateTimeline';
import useGetCampaignUpdates from 'pages/campaign/hooks/useGetCampaignUpdates';
import { ErrorBoundary } from 'react-error-boundary';
const CampaignUpdates = ({ campaignId }) => {
    const { updates, loading, error } = useGetCampaignUpdates(campaignId);
    const { translateChoice: tChoice } = useLanguageProvider();
    if (loading) {
        return _jsx("div", { children: "Loading..." });
    }
    const totalUpdates = updates.totalCount;
    const items = updates.edges;
    if (!items || totalUpdates === 0) {
        return null;
    }
    return (_jsx(ErrorBoundary, { fallback: _jsx(ErrorFallback, { error: error }), children: _jsxs("div", { className: "campaign-container__updates-container", "data-testid": "campaign-updates", children: [_jsxs("h2", { className: "campaign-container__updates-container-title --large", children: [tChoice('Update|Updates', totalUpdates), _jsxs("span", { className: "campaign-container__updates-container-total", "data-test": "campaign-page-updates-container", children: ["(", totalUpdates, ")"] })] }), _jsx(CampaignUpdateTimeline, { updates: items })] }) }));
};
export default CampaignUpdates;
