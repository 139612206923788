import { useQuery } from '@apollo/client';
import { GET_CAMPAIGN_UPDATES } from 'queries/hooks';
import { useCallback, useState } from 'react';
const useGetCampaignUpdates = (campaignId) => {
    const [updates, setUpdates] = useState({});
    const orderUpdatesDesc = useCallback((data) => ({
        ...data,
        edges: [...data.edges].sort((a, b) => {
            const dateA = new Date(a.node.createdAt).getTime();
            const dateB = new Date(b.node.createdAt).getTime();
            return dateB - dateA;
        }),
    }), []);
    const { loading, error } = useQuery(GET_CAMPAIGN_UPDATES, {
        variables: { campaignId },
        onCompleted: (data) => {
            setUpdates(orderUpdatesDesc(data.campaignUpdates));
        },
        onError: (err) => {
            console.error(err);
        },
    });
    return {
        updates,
        loading,
        error,
    };
};
export default useGetCampaignUpdates;
