import React from "react";
import PropTypes from "prop-types";
import InformationTab from "components/InformationTab";
import { IconSupport } from "components/Icon";
import { formatCurrencySymbol } from "helpers/formatCurrency";
import DateDiffForHumans from "helpers/DateDiffForHumans";
import UserListing from "components/UserListing";
import useLanguageProvider from "components/LanguageProvider/hooks/useLanguageProvider";
import ViewMore from "components/ViewMore/ViewMore";

const Supporters = ({
  type, totalSupporters, supporters, owner, viewMoreSupporters, supportersOffset,
}) => {
  const { translate: t, translateChoice: tChoice } = useLanguageProvider();
  return (
    <div
      className={
      type === "fundraiser"
        ? "campaign-container__fundraiser-supporters"
        : "campaign-container__supporters"
    }
    >
      <ViewMore
        display={
        totalSupporters
        !== supporters.length
      }
        expandText={t("View more supporters")}
        children={(
          <div className="campaign-container__supporters-container">
            {supporters && (
            <h2 className="campaign-container__supporters-container-title --large">
              {tChoice("Supporter|Supporters", totalSupporters)}
              <span
                className="campaign-container__supporters-container-total"
                data-test="campaign-page-supporters-container"
              >
                (
                {totalSupporters}
                )
              </span>
            </h2>
            )}

            {supporters
            && supporters.length > 0
            && supporters.map((supporter, i) => {
              const donationDate = DateDiffForHumans(supporter.created_at);
              const fullName = (supporter.name === "" || supporter.is_anonymous) ? "Anonymous" : supporter.name;

              return (

                <UserListing
                  key={`${i}${supporter.id}`}
                  profileImage={supporter.profileImage || null}
                  fullName={fullName}
                  createdAt={donationDate}
                  offlineDonation={
                  supporter.offline
                    ? "- Offline donation"
                    : null
                }
                  amount={formatCurrencySymbol(
                    supporter.amount,
                    supporter.currency_symbol,
                  )}
                />
              );
            })}

            {supporters.length === 0 && (
            <InformationTab
              className="campaign-container__supporters-empty"
              children={<IconSupport />}
              text={t("Become :owner's first supporter by making a donation", { owner })}
              type="supporters"
            />
            )}
          </div>
      )}
        onClick={viewMoreSupporters}
        element="supporters"
        offsetAmount={+supportersOffset}
      />
    </div>
  );
};

Supporters.propTypes = {
  type: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  campaignId: PropTypes.number,
  fundraiserId: PropTypes.number,
};

export default Supporters;
